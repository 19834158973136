<template>
	<div class="view view-defi" v-touch-prevent>
		<div class="content" :class="{ accepted }">
			
			<transition name="main-title" mode="out-in">
				<div v-if="!accepted" class="main-title" key="1">
					<span v-if="opponent_name">{{ opponent_name }}<br>vous a défié!</span>
					<span v-else>Vous avez reçu<br>un défi!</span>
				</div>
				<div v-else class="main-title" key="2">Défi accepté! <div class="underline"></div></div>
			</transition>

			<div class="avatars">
				<div class="vs">VS</div>
				<div class="avatar player">
					<div class="name">Vous</div>
				</div>
				<div class="avatar opponent">
					<div class="name">{{ opponent_name ? opponent_name : "Votre adversaire" }}</div>
				</div>
			</div>

			<div class="buttons">
				<div v-if="!accepted" class="button" v-hammer:tap="() => accepted = true">Accepter le défi</div>
				<div v-else class="button" v-hammer:tap="play" :class="{ loading: loading === 'play' }">
					<ui-icon v-if="loading === 'play'" name="button-loader" :size="40" />
					<span v-else>Jouer</span>
				</div>
				<div v-if="!connected_user" class="button" v-hammer:tap="() => login_visible = true">Se connecter</div>
				<div v-else class="connected">Connecté en tant que:<br>{{ connected_user }}</div>
			</div>
			
		</div>
		
		<transition name="login-form">
			<div v-if="login_visible" class="login-form">
				<div class="bg" v-hammer:tap="() => login_visible = false"></div>
				<form class="form" @submit.prevent="submit_login">
					<div class="title">Connexion</div>
					<div class="line">
						<input type="email" class="input" :class="{ shake: login_error }" placeholder="Courriel" ref="input_email" v-model="email" autocomplete="email">
					</div>
					<div class="line">
						<input type="password" class="input" :class="{ shake: login_error }" placeholder="Mot de passe" v-model="password" autocomplete="current-password">
					</div>
					<div class="line buttons">
						<button type="button" class="button red" v-hammer:tap="() => login_visible = false">Retour</button>
						<button type="submit" class="button green" :class="{ disabled: !loginable, loading: loading === 'login' }">
							<ui-icon v-if="loading === 'login'" name="button-loader" :size="40" />
							<span v-else>Connexion</span>
						</button>
					</div>
					<div class="ticket-bottom">
						<div class="plane"></div>
						<div class="barcode"></div>
					</div>
				</form>
			</div>
		</transition>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'
import { createAnonymousUser, login } from "@/js/jwt/jwt"

export default {
	name: 'view-defi',
	data() {
		return {
			accepted: false,
			challenge_id: null,
			game_challenge: null,
			opponent_name: "",
			login_visible: false,
			email: '',
			password: '',
			loading: null,
			login_error: false
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'user' ]),
		connected_user() {
			return this.user.email
		},
		loginable() {
			return this.email && this.password
		}
	},
	watch: {
		login_error(a) {
			if (a) setTimeout(() => this.login_error = false, 750)
		},
		login_visible(visible) {
			if (visible) {
				this.$nextTick(() => {
					if (this.$refs.input_email) this.$refs.input_email.focus()
				})
			}
		}
	},
	methods: {
		async play() {
			if (!this.loading) {
				this.loading = 'play'
				if (this.connected_user) {
					this.redirect_to_game()
				}
				// Play as anonymous
				else {
					const ok = await createAnonymousUser()
					if (ok) {
						await this.$store.dispatch('get_user')
						this.redirect_to_game()
					} else
						this.loading = null
				}
			}
		},
		async submit_login() {
			if (this.loginable) {
				if (!this.loading) {
					this.loading = 'login'
					this.login_error = false
					const ok = await login({ email: this.email, password: this.password })
					if (ok) {
						await this.$store.dispatch('get_user')
						this.login_visible = false
						this.loading = null
					} else {
						this.login_error = true
						this.loading = null
					}
				}
			}
		},
		redirect_to_game() {
			this.$router.push({ name: 'game', params: { city_slug: this.game_challenge.clientSection.slug, game_id: this.challenge_id, is_challenge: 1 } })
		}
	},
	async mounted() {
		if (this.$route.params.id) {
			await this.$medias.init({ loadPrefetch: false })
			const response = await Apollo.query('CLIENT_USER_CHALLENGE', { id: this.$route.params.id })
			if (response && response.id && response.initialProgression && response.initialProgression.clientGame && response.initialProgression.clientGame.id && response.initialProgression.clientGame.clientSection) {
				this.challenge_id = response.id
				this.game_challenge = response.initialProgression.clientGame
				if (response.initialProgression.clientUser && response.initialProgression.clientUser.profile && response.initialProgression.clientUser.profile.surname) {
					this.opponent_name = response.initialProgression.clientUser.profile.surname
				}
				this.$store.commit('EDIT_APP_STATE', { main_loader: false })
			}
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'

.view-defi
	height 100%
	width 100%
	flex center center
	background linear-gradient(180deg, #5EBFB4 0%, #2E8A9E 100%)
	.content
		width calc(100% - 32px)
		max-width 400px
		margin 0 auto
		color #fff
		text-align center
		flex center center
		flex-direction column
		gap 64px
		&.accepted
			.avatars
				.avatar
					&.player
						transform translateX(-120px) scale(0.6) rotate(-360deg)
					&.opponent
						transform translateX(120px) scale(0.6) rotate(360deg)
					.name
						transition 0.25s easeOutQuart 0.75s
						opacity 1
						transform translate(-50%, 0px)
		.main-title
			width 300px
			height 120px
			flex center center
			font-size 4rem
			line-height 1.3em
			&.main-title-enter-active, &.main-title-leave-active
				transition 0.5s easeOutQuart
				.underline
					transition 0.35s easeInQuart 0.15s
			&.main-title-enter, &.main-title-leave-to
				opacity 0
				.underline
					width 0
			.underline
				position absolute
				left 50%
				bottom 0
				transform translateX(-50%)
				height 3px
				width 100%
				background-color #fff
					
				
		.avatars
			height 140px
			margin 0 0 16px 0
			.avatar
				position absolute
				left calc(50% - 70px)
				top 0
				width 140px
				height 140px
				background center center no-repeat
				background-size 100% 100%
				transition 0.75s easeOutQuart
				&.opponent
					background-image url(../assets/img/avatars/opponent-defi.svg) 
				&.player
					background-image url(../assets/img/avatars/player.svg) 
				.name
					position absolute
					left 50%
					top calc(100% + 16px)
					font-size 3.2rem
					line-height 1.2em
					transform translate(-50%, -8px)
					opacity 0
					pointer-events none
					white-space nowrap
			.vs
				absolute 0
				flex center center
				background-color red
				font-size 4.8rem
				line-height 1em
		.buttons
			flex center center
			flex-direction column
			.button
				flex center center
				height 56px
				width 250px
				margin 0 0 16px 0
				border-radius 8px
				background-color #2E8189
				box-shadow 0px 3px 3px alpha(#000, 40%)
				font-size 2rem
				text-transform uppercase
				cursor pointer
				&:hover
					background-color darken(#2E8189, 10%)
				&:active, &.loading
					background-color darken(#2E8189, 10%)
					transform translateY(3px)
					box-shadow none
				&:last-child
					margin 0
				&.loading
					cursor wait
			.connected
				padding 16px
				margin 8px 0 0 0
				border 2px solid #fff
				border-radius 8px
				font-size 1.6rem
				overflow-wrap anywhere
	
	.login-form
		absolute 0
		flex center center
		transition opacity 0.25s easeOutQuart
		&.login-form-enter, &.login-form-leave-to
			opacity 0
			.form
				transform translateY(-16px)
		.bg
			absolute 0
			background-color alpha(#000, 50%)
		.form
			width calc(100% - 32px)
			max-width 400px
			padding 32px
			border-radius 32px
			background-color #363B4A
			box-shadow 0px 10px 6px alpha(#000, 30%)
			transition 0.25s easeOutQuart
			.title
				margin 0 0 32px 0
				font-size 2.4rem
				text-transform uppercase
				color #fff
				text-align center
			.line
				margin 0 0 16px 0
				&.buttons
					margin-top 32px
					flex center center
					gap 16px
			.input
				display block
				width 100%
				height 48px
				width 100%
				padding 0 24px
				background #eee
				border-radius 40px
				border 2px solid transparent
				box-shadow 0 2px 4px alpha(#000, 25%)
				outline none
				font inherit
				color #353b4a
				line-height 40px
				font-weight 500
				appearance none
				&:focus
					border-color #50b15a
					background-color #fff
				&.shake
					border-color red
					background-color lighten(red, 75%)
					animation shake 0.25s linear infinite
					@keyframes shake
						0%, 100%
							transform translate(0, 0)
						15%
							transform translate(-2px, 1px)
						25%
							transform translate(0px, -1px)
						50%
							transform translate(-1px, 2px)
						65%
							transform translate(-2px, -1px)
						75%
							transform translate(2px, 1px)
			.button
				display inline-block
				user-select none
				display flex
				align-items center
				justify-content center
				height 48px
				width 50%
				padding 0 32px
				background-color dark
				box-shadow 0 2px 4px alpha(#000, 25%)
				border none
				cursor pointer
				border-radius 40px
				font inherit
				line-height @height
				color #fff
				text-transform uppercase
				font-weight 500
				white-space nowrap
				text-decoration none
				&:active, &.loading
					transform translateY(2px)
					box-shadow none
				&.loading
					cursor wait
				&.red
					background-color red
					color #fff
					&:active
						background-color darken(red, 5%)
					.loader.loading
						background-color red
				&.green
					background-color green
					color #fff
					&:active
						background-color darken(green, 5%)
					.loader.loading
						background-color green
				&.disabled
					background-color #ccc
					color #999
					cursor not-allowed
					&:hover, &:active
						background-color #ccc
						transform none
						color #999
			.ticket-bottom
				width 100%
				height 112px
				margin 48px 0 0 0
				display flex
				align-items center
				justify-content flex-end
				flex-direction column
				&:before
					content ''
					position absolute
					top 0
					left -32px
					right -32px
					border-top 3px dashed gray
				.plane
					width 24px
					height 24px
					margin 0 0 8px 0
					background url(../assets/img/plane.svg) center center no-repeat
					background-size auto 100%
				.barcode
					width 100%
					height 48px
					background url(../assets/img/barcode.svg) center center no-repeat
					background-size auto 100%

</style>
